import Noodl from '@noodl/noodl-sdk'
import Validate from './validate'

Noodl.defineModule({
    nodes:[
		Validate
    ],
    setup() {
    	//this is called once on startup
    }
});